import { AsyncStorage } from '../AsyncStorage';
import { getList, save } from '../ApiService';
import { entityNames } from '../../statics/entityNames';

let currentLang = undefined;
const LANG_KEY = 'SELECTED_LANG';
let DICTIONARY = {};
export const availableLanguages = ['az', 'en', 'ru'];
let langChangeEmitters = [];
const TRANSLATIONS_KEY = 'TRANSLATIONS';
const translationUpdateDuration = 1000000; // milliseconds

// set app wide language
export const use = (lang = '') => {
    verifyLanguage(lang);
    lang = lang.toLowerCase();
    currentLang = lang;
    emitLangChange(lang);
    AsyncStorage.setItem(LANG_KEY, lang);
}

// emit all language change callback functions
const emitLangChange = (lang) => langChangeEmitters.forEach((callback = () => console.log('not implemented')) => callback(lang));
const storeDictionaryInAsyncStorage = () => AsyncStorage.setItem(TRANSLATIONS_KEY, JSON.stringify({ DICTIONARY, storeTime: Date.now() }));
// add language change callback function to call on language change
export const addLanguageChangeCallback = (callback) => langChangeEmitters.push(callback);

// throws if teh passed language is not supported
export const verifyLanguage = (lang = '') => {
    if (!availableLanguages.find(x => x === lang.toLowerCase())) {
        console.log(`the languiage: ${lang} is not supported.`);
        return;
    }
}

// returns the app wide used language
export const getCurrentLang = () => {
    return currentLang;
}

// this method must be called only on the opening of the app
export const readAndSetLanguageFromStorage = (callback = () => console.log('not implemented')) => {
    if (currentLang) {
        return;
    }

    AsyncStorage.getItem(LANG_KEY)
        .then(lang => {
            currentLang = lang ? lang : 'en';
            callback(currentLang);
        })
        .catch((err) => {
            callback('en')
        });

}
readAndSetLanguageFromStorage();

const addNewTranslationKey = (key = '') => {
    const data = { key, az: '', en: '', ru: '' };
    save(entityNames.translation, data);
    DICTIONARY[key] = data;
    AsyncStorage.removeItem(TRANSLATIONS_KEY);
}

/**
* Returns the according value from dictionary for the current language. 
* If the current language is not set, or the according value for the passed key in the current language is not set returns the passed key. 
*/
export const translate = (keyOrTranslatableObject, addIfNotExists = true) => {
    if (!keyOrTranslatableObject) return '';

    if (typeof (keyOrTranslatableObject) !== "object") return translateFromDictionary(keyOrTranslatableObject);
    
    return translateFromSameTranslatableObject(keyOrTranslatableObject)
}
const translateFromSameTranslatableObject = (translatableObject) => translatableObject[currentLang]; 

const translateFromDictionary = (key, addIfNotExists = true) => {
    let translation = key;
    if (DICTIONARY[key] && DICTIONARY[key][currentLang]) {
        translation = DICTIONARY[key][currentLang];
    } else if (!DICTIONARY[key] && addIfNotExists) {
        addNewTranslationKey(key);
    }
    return translation;
}

const getDictionaryFromAsyncStorage = () => AsyncStorage.getItem(TRANSLATIONS_KEY).then(data => {
    if (data) {
        const parsed = JSON.parse(data);
        if (parsed && parsed.storeTime && Date.now() - parsed.storeTime < translationUpdateDuration) {
            return parsed.DICTIONARY;
        }
    }
    return undefined;
});

export const initDictionary = async () => new Promise((res) => {
    getDictionaryFromAsyncStorage().then((response) => {
        if (response) {
            DICTIONARY = response;
            // console.log('DICTIONARY loaded from local storage');
            res(DICTIONARY);
        } else {
            getTranslations().then(() => res(DICTIONARY));
        }
    });
});

export const getTranslations = async () => {
    return getList('translation', { _limit: 100000, _sort: 'id:DESC', _start: 0 }).then((response) => {
        if (response && response.data) {
            DICTIONARY = {};
            let extractedColumns = ['_id', 'id', 'createdAt', 'updatedAt', 'key', '__v'];
            response.data.map(row => {
                let wordData = DICTIONARY[row.key] = {};
                return Object.keys(row).map(langKey => {
                    if (!extractedColumns.find(x => x === langKey)) {
                        wordData[langKey] = row[langKey];
                    }
                    return langKey;
                });
            });
            storeDictionaryInAsyncStorage();
            initDictionary();
            // console.log('Translations configured');
        } else {
            console.log('Response data not found', response);
        }
    }).catch((error) => {
        console.log('initTranslationList error: ', error);
    });
}