import Axios from "axios";
import { apiUrl } from "./UrlService";
import { ChatDetail } from "../types";

const options = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlMTg1M2EyZWNkOWRmMTMyNDQzMGMwMyIsImlzQWRtaW4iOnRydWUsImlhdCI6MTU3OTE1ODgyMCwiZXhwIjoxNTgxNzUwODIwfQ.lIMaivyqqH0o0PzsVkOIKXr-nJ7gF0DX_tNkMShfJoQ'
    }
}

const mainApiUrl = `${apiUrl}/content-manager/explorer/application::{{collectionName.collectionName}}`;

const getApiUrl = (collectionName) => `${apiUrl}/content-manager/explorer/application::${collectionName}.${collectionName}`;
const getContentUrl = (collectionName) => `${apiUrl}/content-manager/content-types/application::${collectionName}.${collectionName}}`; 

export const getContentTypes = (collectionName) => Axios.get(getContentUrl(collectionName), options);
const defaultQueryParams = { _limit: 100, _sort: 'id:DESC', _start: 0 };


export const save = (collectionName, request) => (request.id) ?
    Axios.put(`${getApiUrl(collectionName)}/${request.id}`, request, options) :
    Axios.post(getApiUrl(collectionName), request, options);

export const getById = (collectionName = '', id = '') => Axios.get(`${getApiUrl(collectionName)}/${id}`, options);
export const deleteItem = (collectionName = '', id = '') => Axios.delete(`${getApiUrl(collectionName)}/${id}`, options);
export const getList = (collectionName = '', params = defaultQueryParams) => {
    const resolvedParams = {}
    Object.entries({ ...defaultQueryParams, ...params }).forEach(([key, val]) => {
        if (val)
            resolvedParams[key] = val
    });
    return Axios({
        method: 'GET',
        url: getApiUrl(collectionName),
        headers: options.headers,
        params: resolvedParams
    });
};

export const getLead = (id = '') => getById('leads', id);
export const saveLead = (request) => save('leads', request);
export const deleteLead = (id = '') => deleteItem('leads', id);
export const getLeadList = ({ _limit = 10, _sort = 'id:DESC', _start = 0 }) => getList('leads', { _limit, _sort, _start });

export const getTask = (id = '') => getById('tasks', id);
export const saveTask = (request) => save('tasks', request);
export const deleteTask = (id = '') => deleteItem('tasks', id);
export const getTaskList = ({ _limit = 10, _sort = 'id:DESC', _start = 0 }) => getList('tasks', { _limit, _sort, _start });

export const getContact = (id = '') => getById('contact', id);
export const saveContact = (request) => save('contact', request);
export const deleteContact = (id = '') => deleteItem('contact', id);
export const getContactList = ({ _limit = 10, _sort = 'id:DESC', _start = 0 }) => getList('contact', { _limit, _sort, _start });

export const getChatHistory = (chatId) => getList('chat-history', { _limit: 100, chatId }).then(x => x.data);


export const getChatData = async (chatId) => {
    let chat: ChatDetail = await (await getById('chat-queue', chatId)).data;
    if (chat) {
        const history = (await getChatHistory(chat.id)) || [];
        const chatMembers = chat.chatMembers || [];
        chat.messages = history
        .filter(x => Boolean(x.text))
        .map(mes => ({ ...mes, sender: (chatMembers.find(x => x && x.userId === mes.senderId) || {}) }));

        if (history.length) {
            const last = history[history.length - 1];
            chat.lastMessage = last && last.text
        }
        return chat;
    }
    return null;
}
