import { storage } from '../../env'

class StorageInMobileAdapter {
    getItem = (key: string) => new Promise((res, rej) => storage.getItem(key, (errors, result) => {
        if (errors)
            rej(errors)
        else
            res(result)
    }))

    setItem = (key: string, value: string) => new Promise((res, rej) => storage.setItem(key, value, (errors) => {
        if (errors)
            rej(errors)
        else
            res()
    }))

    removeItem = (key: string) => new Promise((res, rej) => storage.removeItem(key, (errors) => {
        if (errors)
            rej(errors)
        else
            res()
    }))
}

class StorageInWebAdapter {
    getItem = async (key: string) => storage.getItem(key)
    setItem = async (key: string, value: string) => storage.setItem(key, value)
    removeItem = async (key: string) => storage.removeItem(key)
}

let envStorage;

if (!window.localStorage)
    envStorage = new StorageInMobileAdapter();
else
    envStorage = new StorageInWebAdapter();

export const AsyncStorage = envStorage;
