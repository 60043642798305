

export const entityNames = {
    relationalDropDownData: 'relational-drop-down-data',
    clinics: 'clinics',
    users: 'app-users',
    customerContacts: 'customer-contacts',
    customer: "customer",
    applicationForm: "application-form",
    appUsers: "app-users",
    departments: "departments",
    messageTemplate: "message-template",
    applicationTypes: "application-types",
    translation: 'translation',
    listViewItems: "list-view-items",
    mainPageNavItems: 'menu-items',
    appConfigs: "app-config",
    FAQ: "faq-questions",
    tempTasks: 'temp-tasks',
    querySettings: 'query-settings',
    queries: 'queries',
    webConfigs: 'web-configs',
    chatBotQuestions:'chat-bot-questions',
    surveys: 'survey'
}