import React, { Fragment, useEffect, useState } from "react";
import BeautyStars from 'beauty-stars';
import { getById, save } from "../ui-common/services/ApiService";
import { entityNames } from "../ui-common/statics/entityNames";
import { toast } from "react-toastify";
import { availableLanguages, getCurrentLang, translate, use } from "../ui-common/services/translation/TranslationService";

function PostCallSurvey(params) {

    const surveyId = params.match.params?.id || null
    const [surveyData, setSurveyData] = useState({});
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (surveyId) {
            fetchSurveyById(surveyId);
        }
    }, [])

    async function fetchSurveyById(id) {
        try {
            setLoading(true);
            const { data, status } = (await getById(entityNames.surveys, id));
            if (status == 200 && data && !data.answered) {
                setSurveyData(data);
                // setTimeout(() => {
                //     getCaptcha();
                // }, 500);
            }
            if (data.answered) {
                toast.info(translate('survey_already_answered'));
                setTimeout(() => {
                    window.location.href = '/';
                }, 5000);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error.response || error);
            toast.error(translate(error?.response?.data?.message || 'an_error_occurred'));
        }
    }

    function handleChange(name, value) {
        setSurveyData({ ...surveyData, [name]: value })
    }

    function handleChangeQuestion(name, value) {
        let updatedQuestions = surveyData.questions;
        updatedQuestions.map((item) => {
            if (item.name == name) {
                item.rateValue = value
            }
        })
        setSurveyData({ ...surveyData, questions: updatedQuestions })
    }

    function changeLang(lang) {
        use(lang);
    };

    function getCaptcha() {
        var alpha = new Array(
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9"
        );
        var i;
        for (i = 0; i < 6; i++) {
            var a = alpha[Math.floor(Math.random() * alpha.length)];
            var b = alpha[Math.floor(Math.random() * alpha.length)];
            var c = alpha[Math.floor(Math.random() * alpha.length)];
            var d = alpha[Math.floor(Math.random() * alpha.length)];
            var e = alpha[Math.floor(Math.random() * alpha.length)];
            var f = alpha[Math.floor(Math.random() * alpha.length)];
            var g = alpha[Math.floor(Math.random() * alpha.length)];
        }
        var code = a + " " + b + " " + " " + c + " " + d + " " + e + " " + f + " " + g;

        document.getElementById("mainCaptcha").innerHTML = code;
        document.getElementById("mainCaptcha").value = code;
    }

    function validCaptcha() {
        var string1 = removeSpaces(
            document.getElementById("mainCaptcha").value
        );
        var string2 = removeSpaces(document.getElementById("txtInput").value);
        if (string1 == string2) {
            return true;
        } else if (!string2.length || !string1.length) {
            toast.error(translate('enter_captcha_code'));
            return false;
        } else {
            getCaptcha();
            toast.error(translate('captcha_is_incorrect'));
            return false;
        }
    }

    function removeSpaces(string) {
        return string.split(" ").join("");
    }

    function validationData() {
        let questionList = surveyData.questions && surveyData.questions.length ? surveyData.questions.filter(x => x.type == surveyData.type) : [];
        let validationResult = true;

        if (questionList.length > 0) {
            questionList.forEach(element => {
                if (element.rateValue === "") {
                    validationResult = false;
                }
            });
        }

        if (!validationResult) {
            toast.error(translate('please_rate_all_questions'));
        }

        return validationResult;
    }

    const saveResult = async () => {
        try {
            if (validationData()) {
                setLoading(true);
                let obj = {
                    id: surveyData.id,
                    answered: true,
                    questions: surveyData.questions,
                    note: surveyData.note
                }
                const { data, status } = await save(entityNames.surveys, obj);
                if (status == 200) {
                    toast.success(translate('successfully_saved'));
                    setSuccessMessage('thanks_for_feedback');
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 5000);
                } else {
                    toast.error(translate('an_error_occurred'), translate('save_failed'));
                }
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error(translate('an_error_occurred'));
        }
    }

    return (
        <Fragment>
            <div className="container">
                <div className="row mt-4">
                    <div className="col-lg-8 col-md-9 m-auto">
                        <h2 className="text-center" style={customStyles.title}>Azerbaijan Tourism Board</h2>
                        <p className="text-center" style={{ marginBottom: '3%', fontSize: '1.5rem' }}>{translate('service_level_evaluation')}</p>
                        <div className="text-center" style={{ marginBottom: '3%' }}>
                            <span>
                                {
                                    availableLanguages.map(lang => (
                                        <button key={lang}
                                            style={customStyles.langItem}
                                            onClick={() => changeLang(lang)}>
                                            <span style={{
                                                textTransform: "capitalize",
                                                textDecoration: getCurrentLang() === lang ? 'underline' : 'none',
                                                color: getCurrentLang() === lang ? 'blueviolet' : '#000',
                                            }}>
                                                {lang}
                                            </span>
                                        </button>
                                    ))
                                }
                            </span>
                        </div>
                        {
                            successMessage ?
                                <h4 className="text-center" style={customStyles.title}>
                                    <b style={{ color: "green" }}>{translate(successMessage)}</b>
                                </h4>
                                :
                                <>
                                    {
                                        surveyData.customer &&
                                        <h3 className="text-center">
                                            <b>
                                                {translate('customer')}:
                                                {
                                                    (surveyData.customer.firstName || surveyData.customer.lastName) ?
                                                        (surveyData.customer?.firstName + ' ' + surveyData.customer?.lastName) : translate('unknown')
                                                }
                                            </b>
                                        </h3>
                                    }
                                    <hr />
                                    {
                                        loading ?
                                            <div className="text-center">
                                                <b className="text-center">
                                                    <b>{translate('loading')}...</b>
                                                    <div>
                                                        <progress></progress>
                                                    </div>
                                                </b>
                                            </div> :
                                            surveyData.id ?
                                                <div>
                                                    {
                                                        surveyData.questions && surveyData.questions.length ?
                                                            surveyData.questions.filter(x => x.type == surveyData.type).map((item, i) => (
                                                                <div style={customStyles.divStyle} key={item.name}>
                                                                    <p><i>- {translate(item.question)}</i></p>
                                                                    <BeautyStars
                                                                        size="20"
                                                                        value={item.rateValue}
                                                                        activeColor="orange"
                                                                        onChange={rateValue => handleChangeQuestion(item.name, rateValue)}
                                                                    />
                                                                </div>
                                                            )) : null
                                                    }
                                                    <div style={customStyles.divStyle}>
                                                        <textarea
                                                            className="form-control col-md-10"
                                                            placeholder="Əlavə qeyd..."
                                                            value={surveyData.note}
                                                            onChange={(e) => handleChange('note', e.target.value)}
                                                        ></textarea>
                                                    </div>

                                                    {/* <div className="mb-4">
                                                        <span id="mainCaptcha" style={{
                                                            border: '1px solid #000',
                                                            padding: 5
                                                        }}>{translate('loading')}...</span>
                                                        <input type="text" id="txtInput" placeholder={translate('enter_code')} className="ml-1" />
                                                        <button type="button" id="refresh"
                                                            onClick={() => getCaptcha()} >{translate('refresh')}</button>
                                                    </div> */}

                                                    <div style={customStyles.divStyle}>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={saveResult}
                                                            disabled={loading}
                                                        >
                                                            {translate('save')}
                                                        </button>
                                                    </div>
                                                </div> :
                                                <div className="text-center">
                                                    <code className="text-center">
                                                        {/* <b>No result</b> */}
                                                    </code>
                                                </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default PostCallSurvey;

const customStyles = {
    divStyle: {
        marginBottom: '3%'
    },
    title: {
        textDecorationLine: "underline",
        marginBottom: '3%'
    },
    langItem: {
        backgroundColor: 'transparent',
        marginRight: 1,
        borderWidth: 0,
        // color: '#dcdcde'
    }
};