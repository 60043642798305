import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as serviceWorker from './serviceWorker';
// PAGES & COMPONENTS
import PostCallSurvey from './pages/PostCallSurvey';
import NoMAtch from './pages/404';
// SERVICES
import { readAndSetLanguageFromStorage, use } from './ui-common/services/translation/TranslationService';
import { initDictionary, addLanguageChangeCallback } from './ui-common/services/translation/TranslationService';
// ASSETS
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import TestPage from './pages/Test';
// import Home from './Home';
// import About from './pages/About';
// import Services from './pages/service/Services';
// import ServiceDetailsLeftSidebar from './pages/service/ServiceDetailsLeftSidebar';
// import ServiceDetailsRightSidebar from './pages/service/ServiceDetailsRightSidebar';
// import Contact from './pages/Contact';
// import SendRequest from './pages/SendRequest';
// import FAQ from './pages/Faq';
// import Privacy from './pages/Privacy';
// import TouristRights from './pages/TouristRights';
// import ChatWidget from './components/ChatWidget';
// import PageNotFound from './pages/404';

class Root extends Component {

    state = { setDictionaryInit: false };

    UNSAFE_componentWillMount() {
        readAndSetLanguageFromStorage(lang => use(lang));
    }

    componentDidMount() {
        initDictionary()
            .then(res => this.setState({ setDictionaryInit: true }))
            .catch(error => {
                console.log('Translation load error: ', JSON.stringify(error));
            });
        addLanguageChangeCallback((lang) => this.setState({ currentLang: lang }));
    };

    renderLoading() {
        return (
            <div className="error-page-wrapper d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-9 m-auto text-center">
                            <div className="text-center">
                                <div className="error-page-content-wrap">
                                    <h3>Loading</h3>
                                    <p>Please wait...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.setDictionaryInit) {
            return (
                <BrowserRouter basename={'/'}>
                    <ToastContainer />
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={TestPage} />
                        <Route exact path={`${process.env.PUBLIC_URL}/evaluate-service/:id`} component={PostCallSurvey} />
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                        <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={About} />
                        <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Services} />
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details-left-sidebar`} component={ServiceDetailsLeftSidebar} />
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details-right-sidebar`} component={ServiceDetailsRightSidebar} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-us`} component={Contact} />
                        <Route exact path={`${process.env.PUBLIC_URL}/send-request`} component={SendRequest} />
                        <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
                        <Route exact path={`${process.env.PUBLIC_URL}/privacy`} component={Privacy} />
                        <Route exact path={`${process.env.PUBLIC_URL}/tourist-rights`} component={TouristRights} />
                        <Route exact path={`${process.env.PUBLIC_URL}/404`} component={PageNotFound} /> */}
                        <Route component={NoMAtch} />
                    </Switch>
                    {/* Chat Widget */}
                    {/* <ChatWidget /> */}
                </BrowserRouter>
            );
        } else {
            return this.renderLoading();

        }
    }

}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.register();
