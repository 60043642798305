import React, { Component } from 'react';

class TestPage extends Component {
    render() {
        return (
            <div className="error-page-wrapper d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-9 m-auto text-center">
                            <div className="error-content-centered d-flex align-items-center justfy-content-center">
                                <div className="error-page-content-wrap">
                                    <h3>Azerbaijan Tourism Agency</h3>
                                    <p>Discovering a land and its people is a truly unique experience.
                                        It takes you on a different journey every time, and opens the mind to seeing something new.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default TestPage;